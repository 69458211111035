
/**  Related Products Gallery **/

.related-prod-container{
    // margin: auto;
    // margin-top: 5rem;
    // @media (max-width: 900px) {
    //     margin-top: 1rem;
    // }
    // margin-bottom: 10rem;
    // width: 80%;
    // @media (min-width: 769px) and (max-width: 1200px){
    //     width: 95%;
    // }
    // @media (max-width: 768px) {
    //     width: 90%;
    // }
   flex-direction: column;
    padding-top: 0;
}
.prod-card-img{
    border-bottom: 1px solid #e2e2e2;
}
.related-prod-title h3{
    color: #222;
    padding-bottom: 10px;
}
.related-prod-cards{
    position: relative;
    margin-top: 5rem;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    @media  (max-width: 768px){
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }
    @media  (min-width: 769px) and (max-width: 1024px){
        gap: 15px;
    }
    
}
.prod-related-zoom{
    overflow: hidden;
    transform: scale(1);
}
.prod-related-zoom:hover img{
    transform: scale(1.1);
    transition: 0.5s linear;
}

.related-prod-img{
    width: 100%;
    background-color: #fff;
    
}
.related-prod-img img {
    width: 100%;
   
}
// .related-prod-img:hover img{
//     transform: scale(1.02);
//     transition: 0.3s ease-in-out;
// }
.prod-card-title h4{
    font-size: 16px;
    padding: 1.5rem;
    color: #222;
    font-weight: 500;
    @media (max-width: 768px) {
        font-size: 14px;
    }
}