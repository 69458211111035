.terms-comp {
  flex-direction: column;

  section {
    height: fit-content;
    padding-bottom: 10px;
    h4{
        padding-bottom: 10px;
    }
  }
  
}
