/* Basic Styles*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* 62.5% of 16px = 10px */
html {
  font-size: 62.5%;
}

body {
  font-family: "Inter", sans-serif;
  color: black;
  font-size: 1.6rem;
  /* background: #f8f8f8; */
}

/* Colors*/
:root {
  --color-primary: #009cad;
  --color-secondary: #f77b00;
  --color-accent1: #060607;
  --color-accent2: #184189;
  --color-accent3: #ececec;
  --color-accent4: #878787;
  --color-primary-dark: #015e68;
}

/* Typography*/

a {
  font-size: 1.6rem;
  color: black;
  text-decoration: none;
  list-style-type: none;
}

li {
  list-style-type: none;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: "Raleway", sans-serif;
}

/* Banner Headlines - h1*/

h1 {
  font-size: 49px;
  margin-bottom: 20px;
  color: #fff;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  line-height: 49px;
  letter-spacing: -1px;
}

@media (max-width: 1024px) {
  h1 {
    font-size: 39px;
    line-height: 39px;
  }
}

@media (max-width: 767px) {
  h1 {
    font-size: 29px;
    line-height: 29px;
  }
}
@media (max-width: 576px) {
  h1 {
    font-size: 20px;
    line-height: 22px;
    font-weight: 600;
  }
}

/* Section Titles - h2*/

h2 {
  margin-bottom: 20px;
  color: rgb(34, 35, 52);
  // font-size: 39px;
  font-size: 39px;
  font-weight: 700;
  letter-spacing: -1.5;
  line-height: 47px;
}
@media (max-width: 1024px) {
  h2 {
    font-size: 31px;
    line-height: 37px;
  }
}
@media (max-width: 767px) {
  h2 {
    font-size: 25px;
    line-height: 30px;
  }
}

@media (max-width: 576px) {
  h2 {
    font-size: 18px;
    line-height: 22px;
  }
}

@media (max-width: 360px) {
  h2 {
    font-size: 16px;
    line-height: 20px;
  }
}

/* Special Cards and other Titles - h3*/

h3 {
  margin-bottom: 10px;
  font-size: 31px;
  color: rgb(34, 35, 52);
  font-weight: 700;
  font-family: "Raleway", sans-serif;
}
@media (max-width: 1024px) {
  h3 {
    font-size: 25px;
  }
}
@media (max-width: 766px) {
  h3 {
    font-size: 22px;
    /* margin-bottom: 0; */
  }
}
@media (max-width: 576px) {
  h3 {
    font-size: 16px;
    line-height: 20px;
  }
}

/* Card, whyus & Testimonial ... Titles - h3*/

h4 {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.5px;
  line-height: 28px;
}
@media (max-width: 1024px) {
  h4 {
    font-size: 18px;
    line-height: 25px;
    //text-align: center;
  }
}
@media (max-width: 767px) {
  h4 {
    font-size: 16px;
    line-height: 22px;
    text-align: left;
  }
  
}
@media (max-width: 576px) {
  h4 {
    font-size: 14px;
    line-height: 22px;
    text-align: left;
  }
  
}

h5 {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 18px;
}
@media (max-width: 1024px) {
  h5 {
    font-size: 16px;
    line-height: 16px;
    /* text-align: center; */
  }
}
@media (max-width: 767px) {
  h5 {
    font-size: 15px;
    line-height: 15px;
    /* text-align: left; */
  }
}
/* Texts- Detail description*/


p {
  color: rgb(97, 99, 121);
  /* margin-bottom: 34px; */
  font-size: 16px;
  line-height: 24px;

  font-family: "Inter", sans-serif;
}
@media (max-width: 1024px) {
  p {
    font-size: 15px;
    line-height: 22px;
  }
}
@media (max-width: 767px) {
  p {
    font-size: 14px;
    line-height: 21px;
  }
}

/* For Samller Texts like in cards ...*/

.p2 {
  font-size: 14px;
  color: rgb(97, 99, 121);
  font-weight: 400;
  font-family: "Inter", sans-serif;
  line-height: 21px;
}
@media (max-width: 1024px) {
  .p2 {
    font-size: 13px;
    line-height: 19px;
  }
}
@media (max-width: 766px) {
  .p2 {
    font-size: 12px;
    line-height: 18px;
  }
}

/* Navbar font styles */
.text {
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500; 
}



@media (max-width: 1024px) {
.text {
  font-size: 15px;
}
}

/* Buttons Styles */
.btn {
  padding: 10px 26px;
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
  background-color: var(--color-primary);
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  width: fit-content;
  cursor: pointer;
  transition: all 0.2s ease-in;
  letter-spacing: 3px;
  line-height: 30px;
}
@media (max-width: 1024px) {
  .btn {
    padding: 10px 22px;
    line-height: 25px;
    letter-spacing: 1px;
    font-weight: 500;
  }
}
@media (max-width: 767px) {
  .btn {
    padding: 10px 18px;
    line-height: 20px;
    letter-spacing: 1px;
    font-weight: 500;
  }
}
.btn:hover {
  background-color: #fff;
  color: #222;
}
.btn2 {
  padding: 10px 26px;
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
  background-color: var(--color-primary);
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  width: fit-content;
  cursor: pointer;
  transition: all 0.2s ease-in;
  letter-spacing: 1px;
  border-radius: 5px;
  line-height: 25px;
  border-radius: 5px;
}
@media (max-width: 1024px) {
  .btn2 {
    margin-top: 40px;
  }
}
.btn2:hover {
  background-color: var(--color-primary-dark);
}
// .btn1 {
//   font-family: Poppins, sans-serif;
//   font-weight: 600;
//   font-size: 15px;
//   color: #019243;
// }


/* Spacing Styles */

.container-wrapper {
  display: flex;
  justify-content: center;
  max-width: 100vw;
  width: 100%;
  /* overflow-x: hidden; */
  padding: 0 80px;
  @media (max-width: 1200px) {
    padding: 0 60px;
  }
  @media (max-width: 992px) {
    padding: 0 40px;
  }
  @media (max-width: 767px) {
    padding: 0 30px;
  }
  @media (max-width: 576px) {
    padding: 0 20px;
  }
}

.container {
  max-width: 1280px;
  width: 100%;
  display: flex;
  justify-content: center;
  //margin: auto;
  
}
@media screen and (max-width:1300px)and (min-width: 900px){
 .container{
  max-width: 1100px;
 } 
}
.container-gap {
  padding-top: 90px;
  padding-bottom: 90px;
}

@media (max-width: 1024px) {
  .container-gap {
    padding-top: 45px;
    padding-bottom: 45px;
  }
}

// @media (max-width: 767px) {
//   .container-gap {
//     padding-top: 28px;
//     padding-bottom: 28px;
//   }
// }


/* Footer Typography*/
.footer-headers{
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  text-transform: capitalize;
}




// Special Button 

.btn-second {
  font-family: "Open Sans", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-weight: 600;
  font-size: 16px;
  @media (max-width:1024px) {
    font-size: 14px;
  }
  background-color: var(--color-primary);
  color:white;
  padding: 14px 28px;
  text-align: center;
  height: 50px;
  border-radius: 5px;
  letter-spacing: 1px;
  
}
.btn-second:hover{
  background-color: var(--color-primary-dark);
  transition: all 0.3s 0s ease-out;
  cursor: pointer;
}

.prod-main-btn {
  font-family: "Open Sans", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-weight: 600;
  font-size: 18px;
  background-color: var(--color-primary);
  color:white;
  padding: 18px 28px;
  text-align: center;
  height: 60px;
  border-radius: 5px;
  letter-spacing: 1px;
  width: fit-content;
}

.prod-main-btn:hover{
  background-color: var(--color-primary-dark);
  transition: all 0.3s 0s ease-out;
  cursor: pointer;
}


.h1{
  font-size: 49px;
  color: #fff;
  font-weight: 700;
  font-family: 'Raleway', sans-serif;
  line-height: 55px;
  letter-spacing: -1px;
}

@media (max-width: 1024px) {
  .h1 {
    font-size: 39px;
    line-height: 45px;
  }
}

@media (max-width: 766px) {
  .h1 {
    font-size: 29px;
    line-height: 35px;
  }
}

@media (max-width: 480px) {
  .h1 {
    font-size: 20px;
    line-height: 25px;
  }
}

@media (max-width: 320px) {
  .h1 {
    font-size: 17px;
    line-height: 20px;
  }
}