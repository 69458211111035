.home-promo-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  background-image: url("../../img/AboutPromoImg.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 130px;
  padding-bottom: 130px;
  position: relative;
  .container {
    justify-content: flex-start;
  }
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.95) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.home-promo {
  width: 100%;
  z-index: 22;

  // @media screen and (min-width: 1025px) and (max-width: 1450px) {
  //     padding: 0 41px;
  // }

  .promo-content {
    max-width: 80%;
    @media (max-width: 576px) {
      max-width: 100%;
    }
  }

  .title {
    margin-bottom: 20px;
    color: rgb(255, 255, 255);
    font-size: 59px;
    font-weight: 700;
    letter-spacing: -1px;
    line-height: 59px;
    margin-bottom: 20px;
    font-family: Raleway, sans-serif;

    @media (max-width: 1024px) {
      font-size: 39px;
      line-height: 42px;
    }

    @media (max-width: 767px) {
      font-size: 29px;
      line-height: 32px;
    }
    @media (max-width: 576px) {
      max-width: 90%;
      font-size: 24px;
      line-height: 28px;
    }
  }

  .text {
    position: relative;
    color: rgba(255, 255, 255, 0.7);
    font-size: 28px;
    font-weight: 400;
    line-height: 1.2em;
    //max-width: 820px;
    margin-bottom: 30px;
    font-family: Inter, sans-serif;

    @media (max-width: 1024px) {
      max-width: 75%;
      font-size: 20px;
      line-height: 24px;
    }

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 19px;
    }
    @media (max-width: 576px) {
      max-width: 90%;
    }
  }
  .abt-promo-btn {
    font-family: "Open Sans", sans-serif;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-weight: 600;
    font-size: 18px;
    background-color: var(--color-primary);
    color: white;
    padding: 14px 48px;
    height: 50px;
    border-radius: 5px;
    text-align: center;
    letter-spacing: 1px;
  }
  .abt-promo-btn:hover {
    background-color: var(--color-primary-dark);
    transition: all 0.3s 0s ease-out;
    cursor: pointer;
  }
}
