.page-number-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    max-width: 789px !important;
      margin-left: 30px;
      width: 100%;
    @media (max-width: 991px) {
      max-width: none !important;
      margin-left: 0 !important;
    }
  }
  
  
  .page-number-box-wrapper >:first-child:hover, .page-number-box-wrapper >:last-child:hover {
    background-color: #ff9901;
    > * {
      color: white;
    }
    border: 0;
  }
.page-number-box {
    cursor: pointer;
    width: 32px;
    height: 32px;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,0.08);
    border-radius: 5px;
    margin: 2px;
    font-size: 14px !important;
    color: #000000DE !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.page-number-box-active {
    background-color: var(--color-primary);
  > * {
    color: white;
  }
  border: 0;
}
.page-number-box-hide {
    display: none;
}
.page-number-box-show {
    display: block;
}