.mainContainer {
    background-color: white;
    width: 100%;
    .container{
        flex-direction: column;
        column-gap: 50px;
    }
    .container h3{
        text-align: left;
        margin-bottom: 0;
    }
    
    .partner-header{
     padding-bottom: 40px;
    }
}
.partner-logo-img{
    height: 40px;
}
.partner-logo-img:hover{
    transition: all .3s ease-out;
    cursor: pointer;
}
.partner-logo-img img{
    width:100%;
   height: 100%;
   margin: auto;
object-fit: contain;
    object-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}


  .slick-prev, .slick-next {
     display: none!important;
  }
  .slick-track{
    display: flex;
    //gap: 50px;
    overflow-x: hidden!important;
    padding-bottom:20px;
  }
//   .slick-dots li button{

//   }
.slick-dots li{
margin: 0!important;

}

.slick-dots {
    overflow-x: hidden!important;
    bottom: -40px;
    }