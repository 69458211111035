.second-sidebar-contact {
    background-color: var(--color-primary);
    padding: 32px;

    @media (max-width: 1024px) {
        padding: 30px;
        margin-bottom: 30px;
    }
    @media (max-width: 767px) {
        padding: 28px;
    }
    @media (max-width: 400px) {
        padding: 15px;
    }
    .phone, .email, .address {
        margin-bottom: 10px;
        display: flex;
        column-gap: 10px;
    }
    >* {
        color: #fff;
        font-weight: 300;
        @media (max-width: 400px) {
            font-size: 12px;
        }
    }
    .title {
        font-family: Raleway, sans-serif;
        font-weight: 700;
        letter-spacing: -0.5;
        line-height: 32px;
        font-size: 25px;
        margin-bottom: 20px;
        color: #fff !important;
        @media (max-width: 1024px) {
            font-size: 21px;
            line-height: 27px;
        }

        @media (max-width: 767px) {
            font-size: 20px;
            line-height: 26px;
        }
    }
    .text {
        font-family: Inter, sans-serif;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 34px;
    }
     .icon {
        padding-right: 10px;
        width: 12px;
        height: 16px;

    }
}