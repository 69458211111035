.prodcard-wrapper {
  background-color: rgb(248, 248, 248);
}

// Prod Headline & Main Button
.prod-container {
  flex-direction: column;
}
.prod-container h2 {
  text-transform: capitalize;
}
.prod-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;
  @media (max-width: 1024px) {
   flex-direction: column;
   align-items: flex-start;
  }
}
.prod-title{
  width: 70%;
  @media (max-width: 1024px) {
    width: 100%;
    margin-bottom: 20px;
   }
}


.prodcard-container {
  position: relative;
  width: 100%;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  overflow: hidden;
  background: white;
}
.prod-grid {
  width: 100%;
  margin: auto;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(4, 24%);
  // column-gap: 10px;
 
  @media (max-width: 1130px) {
    grid-template-columns: repeat(2, 49%);
    // max-width: 900px;
    width: 80%;
    row-gap: 30px;
    column-gap: 30px;
  }
  @media (max-width: 790px) {
    width: 100%;
    column-gap: 0;
    row-gap: 15px;
  }
  @media (max-width: 576px) {
    grid-template-columns: repeat(1, 100%);
    // max-width:400px;
    row-gap: 20px;
    width: 90%;
  }
}
.prodcard-container:first-child {
  margin-left: 0;
}


/* Home Product Card Images*/
.prodcard-img {
  position: relative;
  //width: 100%;
}
.prodcard-img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.card-img-zoom{
  overflow: hidden;
    transform: scale(1);
}
.card-img-zoom:hover img{
  cursor: pointer;
  transform: scale(1.1);
    transition: 0.5s linear;
}
// Product Card Icon

.prodcard-icon {
  position: absolute;
  top: 85%;
  @media (max-width: 1024px) {
    top: 90%;
  }
  right: 40px;
  z-index: 10;
  background: white;
  border-radius: 4px;
}
.prodcard-icon span {
  height: 60px;
  width: 60px;
  line-height: 80px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 36px rgba(43, 43, 43, 0.12);
}
.prodcard-icon span img {
  width: 40px;
  // height: 40px;
}

.prodcard-text {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

// Circle btn
.prodcard-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  height: 28px;
  width: 30px;
  line-height: 35px;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 50%;
  color: white;
  background-color: var(--color-secondary);
  //transition: all 0.3s 0s ease-out;
}

// Product Card Content

.prodcard-content {
  position: relative;
  padding: 40px 20px 20px 20px;
  .prodcard-text {
    z-index: 10;
  }
}

.prodcard-content::before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  height: 100%;
  width: 0px;
  background: var(--color-secondary);
  //opacity: .5;
  transition: all 800ms ease;
}
.prodcard-content {
  h4,
  .prodcard-text {
    position: relative;
    z-index: 10;
  }
  h4{
    @media (min-width:1024px)and (max-width: 1130px){
      font-size: 18px;
    }
  }
}
.prodcard-text .p2{
  @media (min-width:1252px)and (max-width: 1380px){
    height: 63px;;
  }
  @media (min-width:576px)and (max-width: 598px){
    height: 54px;;
  }
}
.prodcard-container:hover {
  .prodcard-content::before {
    width: 100%;
  }
  h4 {
    color: white !important;
  }
  .prodcard-text .p2 {
    color: white;
  }
  .prodcard-text .prodcard-btn {
    color: var(--color-secondary);
    background-color: white;
    cursor: pointer;
  }
  .prodcard-text .prodcard-btn:hover {
    transform: scale(1.1);
    transition: all 0.8 ease-out;
    background-color: rgb(241, 241, 241);
  }
}
