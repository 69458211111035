 /******* Product Characteristics ********/

 .prod-char{
   //  width: 80%;
   //  margin: auto;
   //  padding: 10rem 0;
   justify-content: flex-start;
   padding-top: 0;
    @media (max-width: 900px) {
      //   padding: 4rem 0;
    }
   //  padding-bottom: 7rem;
    @media  (min-width: 1025px) and (max-width: 1200px){
      //   width: 95%;
    }
    @media   (max-width: 1024px){
      //   width: 90%;
    }
    
 }
 .prod-char-wrapper h3{
    color: #222;
    padding-bottom: 10px;
 }
 .prod-char-wrapper hr{
    margin-bottom: 3rem;
 }
 .prod-char-list{
    display: flex;
    align-items: center;
    gap: 15px;
    padding-bottom: 15px;
 }
 .prod-remark{
    font-weight: bold;
    color: #000;   
 }
//  .prod-char-remark{
//     width: 60%;
//  }