.virtual-about-container {
  width: 100%;
  display: flex;
  flex-direction: column;
 padding-bottom: 30px;
}

// Virtual Aout header and button

.virtual-headline {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  margin-bottom: 60px;
  padding-top: 30px;
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.virtual-headline-title{

  width: 60%;
  @media (max-width: 1024px) {
    width: 100%;
    margin-bottom: 20px;
   }
}
.virtual-main-btn {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 18px;
  background-color: var(--color-primary);
  color: white;
  padding: 14px 28px;
  height: 50px;
  border-radius: 5px;
  letter-spacing: 1px;
}
.virtual-main-btn a {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  .v-icon {
    display: flex;
    align-items: center;
  }
}

.virtual-main-btn:hover {
  background-color: var(--color-primary-dark);
  transition: all 0.3s 0s ease-out;
  cursor: pointer;
}

//
.virtual-intro-title {
  margin: 5rem 0;
}
.virtual-intro-title h2 {
  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
}
.vertual-intro-about-img {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.vertual-intro-about-img img {
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.virtual-intro-link {
  margin-top: 5rem;
  margin-bottom: 10rem;
}
.btn-virtual {
  padding: 10px 82px;
  @media screen and (max-width: 700px) {
    padding: 10px 52px;
  }
  font-size: 1.6rem;
  background: #f77b00;
  color: rgb(255, 255, 255);
  border: 1px solid #fff;
  font-weight: 500;
  cursor: pointer;
  border-radius: 10px;
}
.btn-virtual:hover {
  background: rgba(255, 255, 255, 0.2);
  color: rgb(0, 0, 0);
  transition: 0.6s;
  border: 1px solid black;
}
