.video-gallery-wrapper {
    background-color: #fff;

    .video-gallery {
        flex-direction: column;
        // @media (max-width: 767px) {
        //     padding-top: 40px;
        // }
        // @media (max-width: 576px) {
        //     padding-top: 20px;
        // }
        .top {
            display: flex;
            justify-content: center;
            margin-bottom: 30px;
            > h2 {
                text-align: center;
            }
            @media (max-width: 576px) {
                margin-bottom: 20px;
            }
        }

        .bottom {
            display: flex;
            height: 600px;
            width: 100%;
            column-gap: 20px;
            overflow: hidden;

            @media (max-width: 1300px) {
                height: 500px;
            }

            @media (max-width: 992px) {
                flex-direction: column;
                row-gap: 20px;
                height: 100%;
            }

            .left {
                width: 100%;
                height: 100%;

                @media (max-width: 992px) {
                    height: 350px;
                }

                @media (max-width: 576px) {
                    height: 250px;
                    // width: calc(100% + 40px);
                    // margin-left: -20px;
                }

                >iframe {
                    width: 100%;
                    height: 100%;
                }
            }

            .right::-webkit-scrollbar {
                width: 7px;
                /* width of the entire scrollbar */
                
            }
           

            .right::-webkit-scrollbar-track {
                background: #f2f2f2;
                /* color of the tracking area */
            }

            .right::-webkit-scrollbar-thumb {
                background-color: #adacac27;
                /* color of the scroll thumb */
                border-radius: 20px;
                /* roundness of the scroll thumb */
                // border: 1px solid gainsboro;
                /* creates padding around scroll thumb */
                cursor: pointer;
            }

            .right-wrapper {
                width: 450px;
                height: 100%;
                background-color: #fff;

                @media (max-width: 992px) {
                    width: 100%;
                    height: 400px;
                }

                >h3 {
                    width: 100%;
                    margin: 0;
                    padding: 20px 0;
                    // box-shadow: rgba(204, 204, 204, 0.6) 0px 0px 10px 3px;
                    background-color: #f3f3f3;
                    padding-left: 20px;
                    border-bottom: 1px solid #adacac27;
                    font-size: 22px;

                    @media (max-width: 576px) {
                        font-size: 18px;
                    }
                }

                .right {
                    width: 100%;
                    height: calc(100% - 76px);
                    overflow-y: scroll;
                    
                }

                .row {
                    margin: 0px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    padding-left: 20px;
                    padding-right: 10px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    .img-wrapper {
                        width: 70px;
                        height: 60px;

                        @media (max-width: 576px) {
                            width: 50px;
                            height: 50px;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                    }

                    .video-content {
                        width: calc(100% - 70px);
                        @media (max-width: 576px) {
                            width: calc(100% - 50px);
                        }
                        padding-left: 10px;

                        p,
                        h4 {
                            margin: 0;
                            padding: 0;
                            text-align: left;
                        }

                        h4 {
                            font-size: 14px;
                            line-height: 16px;
                            font-weight: 500;
                            font-family: "Inter", sans-serif;

                            @media (max-width: 576px) {
                                font-size: 13px;
                            }
                        }

                        p {
                            font-size: 13px;
                            @media (max-width: 576px) {
                                font-size: 12px;
                            }
                        }
                    }
                }

                .row:hover,
                .row-active {
                    background-color: #f3f3f3 !important;
                    border-left: 3px solid var(--color-secondary);
                    p,
                    h4 {
                        color: var(--color-secondary);
                    }

                }
            }
        }
    }
}