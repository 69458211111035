.footer-continer {
  background-color: #242424;
  position: relative;
}
.footer-wrapper {
  position: relative;
  @media (min-width:1024px) {
    padding-top: 60px!important;
  }
  padding-bottom: 30px !important;
}
.footer-col-wrapper {
  width: 100%;
  display: flex;
  gap: 30px;

  position: relative;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
}
// .footer-col-wrapper .column-footer:first-child {
//   margin-right: 30px;
// }
.footer-col-wrapper div h3 {
  color: white;
}

.footer-logo {
  color: var(--color-primary);
  text-transform: uppercase;
}
.footer-logo-txt {
  color: white;
  font-size: 25px;
  font-weight: 500;
  //margin-bottom: 10px!important;
}
.footer-logo-p p {
  color: #a8a8a8;
  font-size: 15px;
  line-height: 1.3em;
  margin-bottom: 20px;
  @media (max-width: 767px) {
    font-size: 14px;
  }
}

// the Yello horizontal below footer headers
.footer-header-hr {
  width: 30px;
  height: 2px;
  background-color: var(--color-primary);
  color: var(--color-primary);
  opacity: 1;
  border: none;
  margin-bottom: 20px;
}
.hr-logo {
  opacity: 0;
  @media (max-width: 600px) {
    margin-bottom: 0;
  }
}

/* Social Media*/
.social-icons {
  display: flex;
  gap: 15px;
}
.social-icons .icons {
  // width: 36px;
  // height: 36px;
  padding: 8px;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 50%;
  line-height: 34px;
  color: #ffffff;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}
.social-icons .icons:hover {
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  transition: all 0.3s ease-out;
}
// Quick Links
.menu-links {
  margin-bottom: 12px;
}
.menu-links a {
  color: #a8a8a8;
  cursor: pointer;
  font-size: 15px;
  @media (max-width: 767px) {
    font-size: 14px;
  }
}
.menu-links a:hover {
  color: white;
  transition: all 0.3s ease-out;
}

.footer-1,
.footer-2 {
  display: flex;
  width: 50%;
  justify-content: space-between;
  gap: 30px;
  @media (max-width: 1024px) {
    width: 100%;
    gap: 70px;
  }
  @media (max-width: 600px) {
    width: 100%;
    flex-direction: column;
    gap: 30px;
  }
}
.footer-1 .column-logo {
  width: 60%;
  @media (max-width: 1024px) {
    width: 50%;
  }
  @media (max-width: 600px) {
    width: 100%;
    flex-direction: column;
  }
}
.footer-1 .column-menu {
  width: 40%;
  padding-left: 20px;
  @media (max-width: 1024px) {
    width: 50%;
    padding-left: 0;
  }
  @media (max-width: 600px) {
    width: 100%;
    flex-direction: column;
    padding-left: 0;
  }
}
.footer-2 .column-contact {
  width: 60%;
  @media (max-width: 1024px) {
    width: 50%;
  }
  @media (max-width: 600px) {
    width: 100%;
    flex-direction: column;
  }
}
.footer-2 .col-sister-comp {
  width: 40%;
  @media (max-width: 1024px) {
    width: 50%;
  }
  @media (max-width: 600px) {
    width: 100%;
    flex-direction: column;
  }
}

// Find Us
.footer-contact li {
  margin-bottom: 7px;
}
.phone a {
  color: #a8a8a8;
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 10px;
  @media (max-width: 1024px) {
    font-size: 18px;
  }
  @media (max-width: 600px) {
    font-size: 16px;
  }
}
.phone a:hover {
  color: white;
  transition: all 0.3s ease-out;
  cursor: pointer;
}
.address {
  color: #a8a8a8;
  font-size: 15px;
  margin-bottom: 10px;
  @media (max-width: 767px) {
    font-size: 14px;
  }
}
.email-add:hover {
  color: white;
  transition: all 0.3s ease-out;
  cursor: pointer;
}
.gypsum-address {
  padding-top: 13px;
}
.gypsum-txt {
  color: #999999;
  font-size: 11px;
}

// Styles for Need help column
.partner-link {
  margin-bottom: 10px;
}

.sis-comp-links p {
  color: #a8a8a8;
  font-size: 15px;
  @media (max-width: 767px) {
    font-size: 14px;
  }
  line-height: 1.5em;
  margin-bottom: 20px;
  text-align: initial;
}
/* Bottom Footer*/
.copy-right {
  color: #767676;
  font-size: 14px;
  @media (max-width: 1024px) {
    font-size: 12px;
    text-align: center;
  }
}
.copyright-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.terms {
  display: flex;
  gap: 20px;
}

.terms a p {
  font-size: 14px;
  color: var(--color-primary);

  @media (max-width: 1024px) {
    font-size: 12px;
  }
}

// Footer Button

.footer-contact-btn {
  position: relative;
  width: 100%;
  line-height: 28px;
  padding: 10px 25px;
  height: 46px;
  color: #ffffff;
  display: inline-block;
  font-size: 14px;
  background: none;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 3px;
  line-height: 30px;
  letter-spacing: 1px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-contact-btn:hover {
  background-color: var(--color-primary);
  border: 1px solid var(--color-primary);
  transition: all 0.2s ease-out;
}
