.certification-header {
  display: flex;
  flex-direction: column;
  align-self: flex-start;

  h2 {
    margin-bottom: 7px;
  }
  //margin-bottom: 40px;
  p {
    width: 80%;
    @media (max-width: 825px) {
      width: 100%;
    }
   // text-align: center;
  }
}
/* Certification Cards - Grid*/
.certificate-wrapper {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  row-gap: 40px;
}
.cert-card-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  row-gap: 40px;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 576px) {
    grid-template-columns: repeat(2, 2fr);
  }
}

.certificate-cards {
  width: 100%;
}
.certificate-img {
  width: 100%;
}
.certificate-img img {
  display: block;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
/* Certificate Title Styles*/
.certificate-title {
  padding-bottom: 7px;
  h4 {
    font-size: 15px;
    line-height: 24px;
    @media (max-width: 1085px) {
      font-size: 14px;
    }
    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 14px;
    }
  }
  h5 {
    @media (max-width: 1024px) {
      font-size: 12px;
    }
    @media (max-width: 767px) {
      font-size: 10px;
    }
  }
}
