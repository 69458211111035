.proj-card-container {
  .card {
    width: 100%;
    //max-width: 400px;
    box-shadow: rgba(204, 204, 204, 0.6) 0px 0px 10px 3px;
    cursor: pointer;
    //margin: 0 10px;
    //margin-bottom: 30px;
    @media (max-width: 767px) {
      margin: 0;
      margin-bottom: 25px;
    }
    .img-wrapper {
      width: 100%;
      // min-height: 300px;
      position: relative;
      > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      margin-bottom: -4px;
    }
    .overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      opacity: 0;
    }
    .bottom {
      min-height: 64px;
      background-color: #fff;
      width: 100%;
      padding: 12px 10px 12px 10px;
      position: relative;
    }
    .bottom::before {
      position: absolute;
      content: "";
      left: 0px;
      bottom: 0px;
      height: 4px;
      width: 0px;
      background-color: var(--color-secondary);
      transition: all 0.8s ease-out;
      z-index: 333;
    }
  }
  .card:hover {
    overflow: hidden;
    .img-wrapper {
      > img {
        transform: scale(1.2);
        transition: transform 0.8s;
        transition: all 0.8s ease-out;
      }
    }
    .bottom::before {
      width: 100%;
    }
    // margin-top: -4px;
    .overlay {
      opacity: 0.4;
      transition: all 0.8s ease-out;
    }
  }
  .project-title {
    margin-bottom: 10px;
  }
  .project-text {
    color: #797979;
    font-size: 15px;
    @media (max-width: 1024px) {
      font-size: 14px;
    }
    @media (max-width: 767px) {
      font-size: 13px;
    }
    @media (max-width: 576px) {
      font-size: 12px;
    }
  }
}
.proj-wrapper{
  flex-direction: column;
  row-gap: 40px;
}
.proj-wrapper h2{
  margin-bottom: 10px;
}
.proj-card-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 30px;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 2fr);
  }
  @media (max-width: 576px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
