.prefab-videos{
    // width: 80%;
    // @media (min-width: 1201px) and (max-width: 1500px) {
    //     width: 85%;
    // }
    // @media (min-width: 1025px)and (max-width: 1200px) {
    //     width: 95%;
    // }
    // @media (max-width: 600px) {
    //     width: 95%;
    // }
   
    // margin: auto;
    // margin-bottom: 100px;
    flex-direction: column;
    padding-top: 0;
}

.video-container-wrapper{
    display: flex;
    gap: 50px;
    @media (max-width: 1200px) {
       gap: 20px;
    }
    @media (max-width: 1024px) {
        flex-direction: column;
        gap: 30px;
        
    }
    @media (min-width:768px) and (max-width: 1024px) {
        width: 70%;
        margin: auto;
        
    }
}


.prefab-video-container{
    width: 100%;
    margin: auto;
    //margin-top: 30px;
    //margin-bottom: 100px;
}

.prefab-video-intro{
   width: 100%;
    background-color: #e0e0e0;
}
.video-intro-title{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px 20px ;
    gap: 10px;
}
.video-intro-title h3{
 color: #222;
 font-size: 20px;

}
.prefab-video-intro p{
    //color: white;
    font-weight: 300;
    padding: 0 15px 15px 27px;
}
.video-title-wrapper{
    margin-bottom: 50px;
}
.video-title-wrapper h3{
    color: #222;
    padding-bottom: 10px;
}
