.vm-container {
    background-color: #e5e9eb;
    // background-image: url("../../img/why-us-bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .vm {
        // padding-bottom: 0;
        flex-direction: column;
        .top {
            margin-bottom: 50px;
            @media (max-width: 1024px) {
                margin-bottom: 40px;
            }
            @media (max-width: 767px) {
                margin-bottom: 30px;
            }
        }
        .vm-cards-container {
            display: flex;
            column-gap: 80px;

            @media (max-width: 1200px) {
                column-gap: 40px;
            }

            @media (max-width: 767px) {
                row-gap: 40px;
                flex-direction: column;
            }

            .vm-card-wrapper {
                position: relative;
                width: 50%;

                @media (max-width: 767px) {
                    width: 100%;
                }
            }

            .vm-card {
                background-color: #fff;
                box-shadow: 10px 0px 20px rgba(39, 38, 38, 0.1);
                width: 100%;
                height: 100%;
                padding: 40px;

                @media (max-width: 576px) {
                    padding: 20px;
                }

                .vm-card-content {
                    position: relative;
                }

                h4 {
                    display: flex;
                    align-items: center;
                    color: var(--color-secondary);
                    text-transform: capitalize;
                    .v-bar {
                        height: 18px;
                        width: 5px;
                        margin-right: 15px;
                        background-color: var(--color-secondary);
                    }
                }

                p {
                    margin-top: 20px;
                    color: #999999;
                }

                // to display title and text above bg on hover
                h4,
                p {
                    z-index: 222;
                }
            }

            .vm-card::before {
                position: absolute;
                content: '';
                left: 0px;
                top: 0px;
                height: 100%;
                width: 0px;
                background-color: var(--color-secondary);
                transition: all 500ms ease;
            }

            .vm-card-wrapper:hover {
                .vm-card::before {
                    width: 100%;
                }

                .vm-card-content {

                    h4,
                    p {
                        color: #fff;
                    }

                    .v-bar {
                        background-color: #fff;
                    }
                }
            }
        }
    }
}