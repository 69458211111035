.about-us-wrapper {
    .about-us {
        justify-content: space-between;
        align-items: center;
        @media (max-width: 992px) {
            flex-direction: column;
            align-items: center
        }
        .left {
            width: 70%;
            padding-right: 100px;
            @media (max-width: 992px) {
                width: 100%;
                padding-right: 0px;
            }

            h3 {
                margin-bottom: 20px;
                font-size: 30px;
                line-height: 30px;
                margin-bottom: 10px;
            }
            h4 {
                font-weight: 600;
                color: var(--color-secondary);
                color: #656666;
                line-height: 20px;
            }
            p {
                margin-top: 20px;
                font-size: 16px;
                line-height: 22px;
                text-align: justify;
                @media (max-width: 1400px) {
                    font-size: 15px;
                    line-height: 20px;
                }
            }       

            .list-wrapper {
                display: flex;
                justify-content: space-between;
                column-gap: 20px;
                width: 100%;
                padding-top: 20px;
                > div {
                    width: 50%;
                }
                @media (max-width: 350px) {
                    flex-direction: column;
                    > div {
                        width: 100%;
                    }
                }
                p {
                    display: flex;
                    align-items: center;
                }
                .about-icon {
                    display: flex;
                    height: 100%;
                    align-items: center;
                > * {
                    color: var(--color-primary);
                    padding-right: 10px;
                    width: 30px;
                    height: 30px;
                }
            }
            }
        }

        .right {
            width: 30%;
            display: flex;
            justify-content: flex-end;
            @media (max-width: 992px) {
                width: 100%;
                 margin-top: 45px;
            }
            @media (max-width: 767px) {
                margin-top: 35px;
            }
            @media (max-width: 576px) {
                margin-top: 25px;
            }
            .img-wrapper{
                position: relative;
                // margin-bottom: 40px;
                // padding-right: 23px;
                // padding-top: 20px;
                // overflow: hidden; 
                width: 100%;
            }
            // .img-wrapper::before {
            //     position: absolute;
            //     content: '';
            //     right: 0px;
            //     top: 0px;
            //     left: 30px;
            //     bottom: 30px;
            //     background-color: var(--color-primary);
            //     z-index: 11;
            // }
            .img {
                position: relative;
                // max-width: 600px;
                // max-height: 350px;
                // object-fit: cover;
                width: 100%;
                z-index: 22;
                // box-shadow: 10px 0px 20px rgba(39, 38, 38, 0.3);
                @media (max-width: 992px) {  
                    // max-width: 720px;
                    // max-height: 480px;
                    padding-left: 0;
                }
                
            }
            
        }
    }
}