.navbar-wrapper {
    position: relative;
    z-index: 44;
}

.top-wrapper {
    height: 40px;
    background-color: rgb(15, 15, 15);

    .top {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .top-left,
        .top-right {
            >* {
                color: rgb(157, 157, 157);
                margin-bottom: 0;
            }
        }

        .top-left {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            height: 100%;
            .icon {
                margin-right: 10px;
                display: flex;
                align-items: center;
            }

            .phone {
                color: var(--color-primary);
                margin-left: 10px;

                @media (max-width: 370px) {
                    margin-left: 20px;
                }
            }

            @media (max-width: 576px) {
                .cta-text {
                    display: none;
                }
                .icon {
                    margin-right: 5px;
                }
                .phone {
                    margin-left: 0px;
                }
            }
        }

        .top-right {
            display: flex;
            @media (max-width: 1024px) {
                // Hide social media icons on small screens
                .social-media {
                    display: none !important;
                }
            }

            @media (min-width: 1025px) {
                .social-media {
                    display: flex;
                    align-items: center;
                    padding-right: 20px;
                    span {
                        margin: 0 7px;
                        display: flex;
                        align-items: center;
                        > * {
                            width: 17px;
                            height: 18px;
                            color: #cccccc;
                        }
                    }
                    >span:first-child {
                        > * {
                            width: 13px;
                            height: 14px;
                        }
                    }
    
                    span:hover {
                        > * {
                            color: var(--color-primary);
                        }
                    }
                }
            }
           
            .nav-lang {
                display: flex;
                align-items: center;
                > * {
                    margin-bottom: 0;
                    cursor: pointer;
                }
                .bar {
                    font-size: 10px;
                    padding: 0 10px;
                }
            }        
        }
    }
}

.bottom-wrapper {
    position: absolute;
    left: 0;
    top: 40px;
    height: 70px;
    z-index: 55;
    // background-color: rgba(255, 255, 255, 0.85);
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 7px 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    
    .active {
       > * {
        color: var(--color-primary) !important;
       }
    }
    .bottom {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        position: relative;
        align-items: center;
        .btn2 {
            letter-spacing: -0.5;
            line-height: 23px;
            white-space: nowrap;
            font-weight: 500;
        }
        @media screen and (min-width: 1025px) and (max-width: 1200px) {
                
            .btn2 {
                padding: 10px 15px !important;
                letter-spacing: -0.5;
                white-space: nowrap;
                font-size: 12px;
            }
        }

        .bottom-left {
            width: 180px;
            height: 70px;
            padding: 17px 0;
            display: flex;
            align-items: center;
            @media (max-width: 767px) {
                padding: 20px 0;
            }
            > a {
                width: 100%;
                height: 100%;
            }

            >img {
                height: 100%;
                cursor: pointer;
            }
        }

        .bottom-right {
            display: flex;
            align-items: center;
          
            background-color: #fff;
            // z-index: 2222;
            * {
                text-decoration: none;

            }


            @media (min-width: 1025px) {
                height: 100%;
                .social-media {
                    display: none;

                    span {
                        margin: 10px;
                    }
                }
            }

            @media (max-width: 1024px) {
                .social-media {
                    padding: 30px 0;
                    display: flex;
                    span {
                        margin: 0 5px;
                        display: flex;
                        align-items: center;
                        
                        > * {
                            width: 20px;
                            height: 26px;
                            color: #222;
                        }
                    }
                    >span:first-child {
                        > * {
                            width: 25px;
                            height: 18px;
                        }
                        justify-content: flex-end;
                        a {
                            width: fit-content;
                        }
                    }
    
                    span:hover {
                        > * {
                            color: var(--color-primary);
                        }
                    }
                }
            }

            @media (max-width: 1024px) {
                display: none;
            }

            .item {
                margin-right: 20px;
                white-space: nowrap;
                @media (min-width: 1201px) {
                    > * {
                        font-size: 15px !important;
    
                    } 
                }               
                @media screen and (min-width: 1025px) and (max-width: 1200px) {
                    > * {
                        font-size: 14px !important;
                        
                    }
                    margin-right: 20px;
                }

                @media (min-width: 1025px) {
                    height: 100%;
                }

                display: flex;
                flex-direction: column;
                justify-content: center;
                cursor: pointer;

                >* {
                    color: #222;
                }

            }

            .item:last-child {
                margin: 0 !important;
            }

            .item:hover {
                >* {
                    color: var(--color-primary) !important;
                }
            }

            .active {
                color: var(--color-primary) !important;
            }
            
        }

        .mobile-menu {
            display: none;
            cursor: pointer;
            @media (max-width: 1024px) {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
            }

            >* {
                width: 36px;
                height: 36px;
                color: var(--color-primary);
            }
        }
    }

}



.navbar-fixed {
    .bottom-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99 !important;
        // transition-timing-function: ease-in;
        // transition: 0.5s;
        animation-duration: 1s;
        animation-name: fadein;
    }
}

@keyframes fadein {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }


.nav-dropdown-wrapper {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    min-width: max-content;
    width: 100%;

    .nav-link {
        display: flex;
        align-items: center;

        >:first-child {
            padding-right: 5px;
        }
    }

    .nav-dropdown {
        position: absolute;
        left: 0;
        top: 70px;
        width: max-content;
        z-index: 33;
        margin-left: -17px;
        background-color: #fff;

        >* {
            text-decoration: none !important;
        }

        li {
            height: 30px;
            display: flex;
            align-items: center;
            color: rgb(34, 35, 52);
            list-style: none;
            cursor: pointer;
            font-family: 'Be Vietnam Pro', sans-serif;
            font-weight: 400;
            border-bottom: 1px solid #ecebeb9f;
            padding: 20px 15px;
            font-size: 14px;
            white-space: nowrap;
            @media screen and (min-width: 1025px) and (max-width: 1200px) {
                font-size: 13px;
            }
        }

        >:last-child li {
            border-bottom: 0;
        }

        li:hover {
            background-color: var(--color-accent3);
            color: var(--color-primary) !important;
            transition: all 0.18s ease-in;
        }
    }

    .nav-dropdown:last-child {
        border-bottom: 4px solid var(--color-primary);
    }

   

}


.menu-open {
    background-color: rgba(255, 255, 255, 1);
    z-index: 222222;
    .bottom {
        @media (max-width: 1024px) {
            padding: 0 40px;
          }
          @media (max-width: 767px) {
            padding: 0 30px;
          }
          @media (max-width: 576px) {
            padding: 0 20px;
          }
    }
    @media (max-width: 1024px) {
        .bottom-right {
            background-color: #fff;
            display: flex !important;
            flex-direction: column;
            align-items: flex-start;
            position: absolute;
            top: 70px;
            left: 0;
            height: 100vh;
            z-index: 77;
            width: 100%;
            padding-top: 20px;

            .item {
                width: 100%;
                margin-right: 0 !important;
                min-height: 44px;
                border-top: 1px solid rgba(1, 146, 67, 0.15);
                cursor: pointer;

                a {
                    display: flex;
                    align-items: center;
                    width: 100%;
                }

            }

            .item:hover {
                background-color: var(--color-accent3);
            }

            .item1 {
                padding-left: 20px;
                @media (max-width: 1024px) {
                    padding-left: 40px;
                  }
                  @media (max-width: 767px) {
                    padding-left: 30px;
                  }
                  @media (max-width: 576px) {
                    padding-left: 20px;
                  }
            }

        }


        .nav-dropdown-wrapper {
            padding: 0;
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            >div:first-child {
                width: 100%;
                min-height: 44px;
                display: flex;
                align-items: center;
                padding-left: 20px;
                @media (max-width: 1024px) {
                    padding-left: 40px;
                  }
                  @media (max-width: 767px) {
                    padding-left: 30px;
                  }
                  @media (max-width: 576px) {
                    padding-left: 20px;
                  }
            }
        }

        .nav-dropdown {
            position: relative !important;
            top: 0;
            width: 100%;
            margin: 0;
            padding-right: 0;

            li {
                height: 40px;
                padding: 17px 15px;
                width: 100%;

            }
        }

        .nav-dropdown a li {
            padding-left: 51px;
        }
    }
}
