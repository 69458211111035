.product--container {
    @media(max-width: 1024px) {
        flex-direction: column;
    }
}

.product-list {

    padding-right: 20px;
    padding-right: 0;
    width: 325px;
    @media(max-width: 1440px) {
        width: 310px;
    }
        
    @media(max-width: 1024px) {     
        width: 100%;
        padding: 0;
        padding-bottom: 20px;
    }
}

.card-list {
    max-width: 1050px;
    width: 100%;
    column-gap: 20px;
    row-gap: 40px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @media(max-width: 640px) {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 10px;
        row-gap: 20px;
    }
    padding-left: 30px;
    @media (max-width: 1024px) {
        padding-left: 0;
    }
    height: fit-content;
}

.collpsible-main--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    height: 78px;
    width: 100%;
    > * {
        color: #fff;
        margin: 0;
    }
    padding: 15px 24px 15px 36px;
    @media(min-width: 1025px) {
        pointer-events: none;
    }

    @media(max-width: 1024px) {
        height: 58px;
        padding: 10px 24px 10px 36px;
    }
    @media (max-width: 767px) {
        height: 48px;
    }
    @media (max-width: 576px) {
    }
   
    border-bottom: 2px solid #fff;
    background-color: var(--color-primary);
    color: #fff;
}

.collpsible--icon {
    @media(min-width: 1025px) {
        display: none;
    }
}

.collapsible--active {
    background-color: var(--color-primary);
    color: #fff;
}

.collpsible--header {
    margin: 0;
    min-height: 78px;
    width: 100%px;
    padding: 15px 24px 15px 36px;
    border-bottom: 2px solid #fff;
    background-color: var(--color-primary);
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media(min-width: 1025px) {
        pointer-events: none;
    }
}

.collapsible--btn {
    z-index: 2;
    height: 67px;
    width: 100%;
    padding: 20px 24px 20px 36px;

    @media(max-width: 1024px) {
        padding: 10px 20px 10px 36px;
        height: 57px;
    }
    @media (max-width: 767px) {
        padding: 5px 20px 5px 36px;
        height: 47px;
    }

    cursor: pointer;
    color: #222;
    background-color: #ECECEC;;
    border-bottom: 2px solid #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.collapsible--list {
    padding-left: 20px;
}

.content-parent, .main-content-parent {
    height: auto;
    overflow: hidden;
    transition: height linear 0.9s;
}

.content {
    padding: 10px 0;
}

.content, .main-content {
    width: 100%;  
    background-color: #fff;
    .content--list {
        margin: 0;
        height: 46px;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 10px 20px;

        @media (max-width: 1024px) {
            padding: 7px 20px;
            height: 40px;
        }
        @media (max-width: 767px) {
            padding: 5px 20px;
            height: 36px;
        }
        @media (max-width: 576px) {
            padding: 0px 20px;
        }  
        color: #444;
        cursor: pointer;
    }
}

.custom-card {
    max-width: 334px;
    min-width: 144px;
    width: 100%;
    background-color: #fff;
    cursor: pointer;
    max-height: 435px;
    box-shadow: rgba(204, 204, 204, 0.185) 0px 0px 10px 3px;
}

.card-img--box {
    position: relative;
    margin: auto;
    overflow: hidden;
    width: 100%;
    max-height: 272px;
    height: 15.34vw;
    @media(max-width: 1024px) {
        height: 29vw;
    }
}

.card--img {
    object-fit: cover;
    max-width: 100%;
    transition: all 1s;
    display: block;
    width: 100%;
    height: 100%;
    transform: scale(1);
}



.card-img--overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 100%;
  height: 15.34vw;
  transform: scale(0);
 transition: 1s ease-in-out;
 transition-property: opacity, left, top, height;
 opacity: 0;
}

.card--name {
    width: 100%;
    height: 54px;
    @media(max-width: 1090px) {
        height: 70px;
    }
    @media(max-width: 780px) {
        height: 80px;
    }
    padding: 15px;
    // font-size: 18px;
    // font-weight: 500;
    @media(max-width: 480px) {
        // font-size: 16px;
        height: 64px;
    }
    color: #222;
    margin: 0;
    border-bottom: 1px solid #dddbdb;
}



.card--btn {
    width: 100%;
    height: 54px;
    font-size: 18px;
    border: 0;
    text-align: left;
    background-color: #fff;
    padding-left: 15px;
    display: flex;
    align-items: center;
    h5 {
        padding-left: 15px;
    }
    @media (max-width: 1024px)  {
        height: 52px;
    }

    @media(max-width: 640px) {
        font-size: 16px;
        padding-left: 15px;
    }
    cursor: pointer;
}
.card-btn-wrapper {
    position: relative;
    width: 100%;
    .card-btn-overlay {
        z-index: 11;
    }
    h5, .card-btn-icon {
        z-index: 22;
    }
    .card-btn-icon {
        > * {
            color: var(--color-secondary) !important;
        }
    }
}
.card-btn-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    background-color: var(--color-secondary);
}
.custom-card:hover {
    .card-img--box img {
        transform: scale(1.2);
    }
    
    .card-img--box .card-img--overlay {
        transform: scale(1);
        opacity: 1;
    }
    .card-btn-icon, h5 {
        color: #fff !important;
        > * {
            color: #fff !important;
        }
    }
    .card-btn-overlay {
        width: 100%;
        transition: all 1s ease;
    }
    .card--name {
        color: var(--color-secondary) !important;
    }

}

.sidebar-contact {
    width: 100%;
    margin-top: 20px;

    @media(max-width: 1024px) {
        display: none;
    }
    > img {
        width: 100%;
    }
}

.sidebar-contact--content {
    padding: 38px 10px;
    padding-bottom: 20px;
    background-color: #fff;
    width: 100%;
}

.contact--icon {
    width: 40px;
    height: 40px;
    object-fit: cover;
}

.sidebar-contact--info {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 30px;
    

    h3 {
        font-size: 20px;
        color: #797979;
        font-style: italic;
        margin-bottom: 5px;
        font-weight: 300;
    }

    p {
        font-size: 15px;
        font-weight: 400;
        color: #222;
        width: 100%;
        margin-bottom: 0;
        @media(max-width: 1440px) {
            font-size: 14px;
        }
    }
    .sidebar-contact-text:hover {
        color: var(--color-secondary) !important;
    }
}

.sidebar-contact--btn {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-primary);
    color: white;
    font-size: 22px;
    font-weight: 500;
    font-style: italic;
    img {
        padding-right: 10px;
    }
    cursor: pointer;
}

.content {
    .list-active {
        > * {
            color: #f77b00 !important;
        }    
    }
}
