// Contact cards

.contact-add-map {
  flex-direction: column;
  align-items: center;
}

.contact-container {
  flex-direction: column;
}
.contact-header {
  h2 {
    margin-bottom: 10px;
    text-align: center;
  }
  p {
    width: 70%;
    text-align: center;
    margin: auto;
    @media (max-width: 767px) {
      width: 100%;
    }
  }
}
.contact-info {
  padding-top: 50px;
  display: flex;
  width: 100%;
  gap: 20px;
  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.contact-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgb(252, 252, 252);
  box-shadow: 0 3px 15px rgba(12, 12, 12, 9%);
  border-radius: 5px;
  @media (max-width: 767px) {
    width: 80%;
    margin: auto;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 576px) {
    width: 100%;
  }
}
.contact-card {
  > *h5 {
    font-size: 16px;
    font-family: Inter, sans-serif;
    font-weight: 500;
    margin-bottom: 10px;
    line-height: 20px;
    color: var(--color-accent2);
    @media (max-width: 1024px) {
      font-size: 14px;
    }
    @media (max-width: 1024px) {
      text-align: center;
    }
    .gypsum-number {
      margin-bottom: 0;
    }
  }
}

.contact-card-header {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 30px;
  @media (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
  }

  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
  padding: 30px 20px 0 20px;
  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 30px 10px 0 10px;
  }
}

.contact-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-secondary);
  padding: 14px;
  border: 1px solid transparent;
  border-radius: 50%;
  background-color: rgba(247, 123, 0, 0.2);
}
.p-number,
.email-address,
.factory-address {
  padding: 0 20px 30px 20px;
  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 0 10px 30px 10px;
  }
}

.p-number span {
  font-size: 12px;
  color: var(--color-accent4);
}
@media (max-width: 1024px) {
  .p-number {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.contact-card-title {
  @media (max-width: 1024px) {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.contact-card-title span {
  color: var(--color-accent4);
  font-size: 16px;
  @media (max-width: 1024px) {
    font-size: 14px;
  }
}

// Google Maps

.contact-section-2 {
  width: 100%;
  iframe {
    width: 100%;
    border-style: solid;
    border-width: 10px 10px 10px 10px;
    border-color: #FFFFFF;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.18);
    //border: none;
    height: 550px;
     @media (max-width: 768px) {
    height: 450px;
  }
  }
 
}

// Contact Form

.form-header {
  text-align: center;
  margin-bottom: 50px;
  h2{
    margin-bottom: 10px;
  }
  p{
    width: 70%;
    @media (max-width: 767px) {
      width: 100%;
    }
    margin: auto;
  }
}

.contact-form {
  flex-direction: column;
  width: 100%;
}
.form-intro {
  width: 80%;
  @media (max-width:1024px) {
    width: 100%;
  }
  margin: auto;
}
.row-1 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }
  column-gap: 20px;
  input {
    width: 100%;
    background-color: #f7f7f7;
    border: none;
    outline: none;
    border-color: 1px solid #f8f8f8;
    color: #656565;
    font-size: 14px;
    padding: 14px 0 18px 14px;
    border-radius: 5px;
  }
  input:focus {
    border: 1px solid var(--color-secondary);
  }
}
.row-2 {
  display: grid;
  grid-template-columns: repeat(1, 2fr);
  textarea {
    width: 100%;
    background-color: #f7f7f7;
    outline: none;
    border: #f8f8f8;
    color: #656565;
    font-size: 14px;
    padding: 14px 0 18px 14px;
    border-radius: 5px;
    height: 150px;
  }
  textarea:focus{
    border: 1px solid var(--color-secondary);
  }
}

.input-wrapper {
  margin-bottom: 20px;
}

.form-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    border: none;
  }
}

// .contact-main-container {
//   width: 100%;
//   h2,
//   h3,
//   span {
//     color: black;
//     font-weight: 400;
//   }

//   h2 {
//     font-size: 4.4rem;
//     @media (max-width: 1024px) {
//       font-size: 3.5rem;
//     }
//     padding-top: 5%;
//     // padding-left: 5%;
//     @media (max-width: 767px) {
//       font-size: 3rem;
//     }
//     @media (max-width: 480px) {
//       font-size: 2.5rem;
//     }
//   }
//   h3 {
//     font-size: 3.5rem;
//     @media (max-width: 1024px) {
//       font-size: 2.5rem;
//     }
//     @media (max-width: 767px) {
//       font-size: 2rem;
//     }

//     @media (max-width: 480px) {
//       font-size: 1.8rem;
//     }
//   }
//   span {
//     font-weight: 300;
//     @media (max-width: 767px) {
//       font-size: 1.6rem;
//     }
//     @media (max-width: 480px) {
//       font-size: 1.4rem;
//     }
//   }
// }
// .contact-section-1 {
//   display: flex;
//   @media (max-width: 767px) {
//     flex-direction: column;
//     align-items: center;
//   }
//   justify-content: center;
//   align-items: center;
// }
// .contact-address {
//   width: 50%;
//   @media (max-width: 767px) {
//     width: 100%;
//   }
//   padding: 5%;
//   display: flex;
//   flex-direction: column;
// }
// .contact-headquarter,
// .contact-production {
//   display: flex;
//   flex-direction: column;

//   row-gap: 1rem;
//   span span {
//     padding-left: 2rem;
//   }
//   padding: 3rem;
// }

// .contact-form {
//   padding: 5%;

//   width: 50%;
//   @media (max-width: 767px) {
//     width: 100%;
//   }
//   form {
//     display: flex;
//     flex-direction: column;
//     padding: 4rem 0;
//     max-width: 60rem;
//   }
//   input,
//   textarea {
//     min-width: 28rem;
//     width: 100%;
//     padding: 10px;
//     margin: 5px;
//     border-radius: 5px;
//     border: 0;
//   }
//   form input,
//   form textarea {
//     margin-bottom: 1rem;
//     padding: 1rem 1.8rem;
//     font-size: 1.2rem;
//     // background-color: rgba(0, 0, 0, 0.5);
//     border-color: rgb(123, 123, 123);
//     // border-color:rgb(255, 255, 255) ;
//   }
//   div {
//     padding: 5px;
//   }
// }
// .contact-info-row {
//   display: flex;
//    gap: 2rem;
//    padding: 1rem 1rem;
// }

// .contact-section-2 {
//   width: 100%;
//   height: 70rem;

//   @media (max-width: 1024px) {
//     height: 40rem;
//   }
//   @media (max-width: 480px) {
//     height: 30rem;
//   }
//   iframe {
//     height: 100%;
//     width: 100%;
//   }
// }
