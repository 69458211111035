.privacy-container {
    width: 100%;
    height: 100%;
    padding: 3% 8%;
    display: flex;
    justify-content: center;
    @media(max-width: 767px) {
        flex-direction: column;
        align-items: center;
    }
}

.privacy-sidebar-container {
    background-color: rgba(0,156,173,0.02);
    width: 25%;
    margin-right: 5%;
    @media(max-width: 1024px) {
        width: 30%;
    }
    @media(max-width: 767px) {
        width: 95%;
        padding-right: 0;
    }

    input {
        display: none;
    }

    label {
        width: 100%;
        color: black;
        font-size: 20px;
        @media(max-width: 1024px) {
            font-size: 18px;
        }
        @media(max-width: 767px) {
            font-size: 16px;
        }
        font-weight: 400;
        display: flex;
        justify-content: space-between;
        padding: 2% 3%;
        margin: 1% 0;
        cursor: pointer;
    }

    ul {
        display: none;

        li {
            line-height: 40px;
            padding: 8px 20px;
            @media(max-width: 1024px) {
                padding: 6px 15px;
            }
            @media(max-width: 767px) {
                padding: 3px 15px;
            }
            cursor: pointer;
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);

            a {
                color: black;
                text-decoration: none;
                font-size: 16px;
                @media(max-width: 767px) {
                    font-size: 14px;
                }
                font-weight: 300;
                display: block;
            }

            a:hover {
                color: cyan;
            }
        }
    }
}

[id^=btn]:checked+ul {
    display: block;
}
.privacy-sidebar-active {
    background-color: #009cad;
    span, .sidebar-caret-down {
        color: #fff;
    }
}

.privacy-content-container{
    background-color: rgba(0,156,173,0.02);
    width: 75%;
    padding: 2%;
    @media(max-width: 1024px) {
        width: 70%;
    }
    @media(max-width: 767px) {
        width: 95%;
    }

}

.privacy-content{
    display: none;
}

.privacy-content-active{
    display: block;
    margin-left: 5%;
    @media(max-width: 767px) {
        padding-left: 0;
        padding-top: 3%;
    }
}
.privacy-content-section{
    h3{
        padding-bottom: 1%;
    }
    p{
        padding-bottom: 4%;
    }
    

    @media(max-width: 1024px) {
        h3{
            font-size: 3rem;
        }
        p{
            font-size: 1.6rem;
        }
    }

    @media(max-width: 767px) {
        h3{
            font-size: 2.5rem;
        }
        p{
            font-size: 1.6rem;
        }
    }

    @media(max-width: 480px) {
        h3{
            font-size: 2rem;
        }
        p{
            font-size: 1.4rem;
        }
    }
}
