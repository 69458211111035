.testimonal-wrapper {
  background-color: rgba(242, 242, 242, 0.1);
  //padding: 80px;
  .testimonal {
    flex-direction: column;
    align-items: center;
    .top {
      width: 100%;
      > * {
        text-align: center;
      }
      p {
        width: 60%;
        @media (max-width: 1024px) {
          width: 75%;
        }
        @media (max-width: 767px) {
          width: 100%;
        }
        @media (max-width: 490px) {
          width: 100%;
        }
        margin: auto;
      }
      .test-title {
        margin-bottom: 20px !important;
        @media (max-width: 576px) {
          font-size: 18px;
          line-height: 22px;
      }
      }
    }
    .card-container {
      width: 100%;
    }

    .card {
      position: relative;
      padding: 45px 30px 45px 30px;
      //display: flex;
      max-width: 500px;
      min-height: 260px;
      
        
      margin: 0 15px;
      @media (max-width: 1024px) {
        margin: 0 10px;
      }
      @media (max-width: 800px) {
        max-width: none;
        min-height: 0;
      }
      @media (max-width: 600px) {
        flex-direction: column;
        align-items: center;
        margin: 0;
      }
      @media (max-width: 600px) {
        flex-direction: column;
        align-items: center;
        margin: 0;
      }
      .img-wrapper {
        position: absolute;
        width: 35px;
        height: 35px;
        > img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: left;
        }
        @media (max-width: 600px) {
          margin: 0;
        }
      }
      .testim-content{
        width: calc(100% - 50px);
        margin-left: 35px;
        .p2 {
          margin-top: 5px;
          width: 60%;
        }
        .p1 {
          margin-top: 25px;
          margin-bottom: 30px;
        }
        @media (max-width: 600px) {
          width: 100%;
        }
        .test-name {
          text-transform: uppercase;
          font-size: 14px !important;
          font-weight: 700;
        }
        .test-position {
          font-family: "Lora", serif;
          line-height: 1.875;
          font-size: 16px;
          margin-left: 2px;
          color: #616379;
        }
        .testimonial-info {
          display: flex;
          align-items: baseline;
        }
      }
      // Dots
      span .swiper-pagination-bullet {
        width: 25px !important;
        display: inline-block;
        height: 5px;
        background-color: #262626;
        width: 25px;
        border-radius: 5px;
        margin: 0 12px;
      }
    }
  }
}
.swiper-pagination-bullet .swiper-pagination-bullet-active {
  width: 25px !important;
  display: inline-block;
  height: 5px;
  background-color: #a01616;
  width: 25px;
  border-radius: 5px;
  margin: 0 12px;
}

// .card-container {
//   :global(.swiper-pagination-bullet) {
//     width: 25px !important;
//     display: inline-block;
//     height: 5px;
//     background-color: #a01616;
//     width: 25px;
//     border-radius: 5px;
//     margin: 0 12px;
//   }

//   :global(.swiper-pagination-bullet-active) {
//     background-color: blue;
//     width: 5rem;
//     height: 1rem;
//     border-radius: 0.5rem;
//   }
// }
.swiper-nav-btns{
    display: flex;
    align-items: center;
    justify-content: center;
}
.swiper-nav-btns span{
    width: 25px;
    display: inline-block;
    height: 5px;
    background-color: var(--color-primary);
    border-radius: 5px;
    margin: 0 12px; 
    cursor: pointer;
    
}
.swiper-nav-btns span:hover{
    background-color: #009cad;
    box-shadow: 0 0 13px  rgba(0, 156, 173, 0.8);
}
// .testimonials-container {
//   width: 100%;
//   background: url("./Testimonials-bg.jpg");
//   background-repeat: no-repeat;
//   background-size: cover;
//   padding: 5% 2%;
//   .slick-slider{
//       width: 80%;
//       margin-left: 10%;
//       height: 100% !important;
//     }
//     .slick-dots{
//      >*{
//       color: white !important;
//      }
//     }

// }

// .testimonials-header{
//   padding: 5%;
//   h2 {
//       color: white;
//   }
// }

// .testimonial-card{
//   //min-width: 280px;
//   height: 570px;
//   @media(max-width: 1700px) {
//     height: 600px;
//   }
//   @media(max-width: 1580px) {
//     height: 500px;
//   }
//   @media(max-width: 1350px) {
//     height: 570px;
//   }
//   @media(max-width: 1130px) {
//     height: 620px;
//   }
//   // @media(max-width: 980px) {
//   //   height: 450px;
//   // }
//   @media(max-width: 980px) {
//     //height: 450px;
//     height: auto;
//   }
//   padding-bottom: 5%;
//   @media(max-width: 767px) {
//     //height: 450px;
//   }
//   @media(max-width: 620px){
//     //height: 600px;
//   }
//   margin: 2%;
//   background-color: #ececec;
//   opacity: 0.7;
//   justify-content: center;
//   align-items: center;
//   vertical-align: middle;
//   box-shadow: 3px 3px 15px rgb(0, 0, 0);
// }

// .testimonial-body{
//     padding-left: 3rem;
//     margin-bottom: 5rem;
//   }

//   .testimonial-text p {
//     color: #000;
//     margin-top: 3rem;
//     width: 90%;
//     @media(max-width: 480px) {
//       width: 100%;
//       padding-right: 2rem;
//     }
//   }

//   .testimonial-text{
//     padding-left:3rem;
//     text-align: left;
//     justify-content: center;
//     align-items: center;
//     @media(max-width: 480px) {
//       width: 100%;
//       padding: 0;
//     }
//   }

//   .testimonial-line-y{
//     @media(max-width: 480px) {
//       display: none;
//     }
//     border: 2px solid;
//     color: #F77B00;
//     height: 10rem;
//     position: absolute;
//   }

//   .testimonial-card .testimonial-icon{
//     padding: 0 40%;
//     margin:2rem 0;
//     // height: 48rem;
//     // width: 33rem;

//   }

//   .testimonial-people {
//     margin: 0 3rem;
//     margin-bottom: 3rem;
//     height: 100%;
//     display: flex;
//     justify-content: center;
//     img{
//       width: 60px;
//       height: 60px;
//       float: left;
//       margin-right: 3rem;
//     }
//   }

//   .testimonial-name {
//     color: #000;
//     font-weight: 600;
//   }
//   .testimonial-status{
//     color: #808080;
//     font-size: 1.6rem;
//     padding-top: 10px;
//   }
