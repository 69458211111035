.core-values-wrapper {
  position: relative;
  // bottom background 
  .bottom-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 300px;
    width: 100%;
    background-color: #e5e9eb;
  }

  .core-values {
    z-index: 22;
    width: 100%;
    height: 100%;
    background-color: rgb(40, 45, 49);
    padding: 75px 40px 40px 40px;
    flex-direction: column;
    @media (max-width: 992px) {
      padding: 35px 10px 20px 10px;
    }

    // top headline text styles
    .top {
      @media (max-width: 1400px) {
        padding-left: 10px;
      }

      @media (max-width:767px) {
        padding-left: 20px;
      }

      @media (max-width: 576px) {
        padding-left: 0;
      }

      // top main title
      h3{
        color: #fff;
        margin-bottom: 10px;
      }

      // top sub title
      .h4 {
        color: var(--color-secondary);
        font-weight: 400;
        margin-bottom: 50px;
  
        @media (max-width:767px) {
          margin-bottom: 40px;
        }
  
        @media (max-width: 576px) {
          margin-bottom: 30px;
        }
      }
    } 

    // core value items styles
    .core-values-container {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      column-gap: 100px;

      @media (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(4, 1fr);
      }

      @media (max-width: 1400px) {
        column-gap: 50px;
      }

      // core value item
      .core-value-item {
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        
        @media (max-width: 992px) {
          padding: 0 20px;
          align-items: center;
        }

        @media (max-width: 767px) {
          flex-direction: row;
          align-items: flex-start;
        }

        @media (max-width: 576px) {
          flex-direction: column;
          align-items: center;
          padding: 0;          
        }

        // icons styles
        .icon-wrapper {
          width: 50px;
          height: 50px;
          margin-bottom: 20px;

          @media (max-width: 767px) {
            margin-bottom: 0;
            margin-right: 20px;
          }

          @media (max-width: 576px) {
            margin-right: 0;
            margin-bottom: 20px;
          }

          img {
            width: 100%;
            height: 100%;
          }
        }

        // core value item title 
        h4 {
          color: #fff;
        }

        // core value text styles 
        p {
          margin-top: 5px;
          margin-bottom: 50px;
          color: rgb(204, 204, 204);

          @media (max-width: 767px) {
            margin-bottom: 30px;
            max-width: 500px;
          }

          @media (max-width: 576px) {
            margin-bottom: 20px;
            max-width: 400px;
          }
        }
      }
    }

    // changing text aligments
    h4,
    h3,
    p {
      @media (max-width: 992px) {
        text-align: center;
      }

      @media (max-width: 767px) {
        text-align: left;
      }

      @media (max-width: 576px) {
        text-align: center;
      }
    }

  }
}