.product-details-container {
 
  @media (max-width: 1024px) {
    flex-direction: column;
  }
  }
.gallery-slider {
  overflow: hidden;
  background-color: white;
  width: 50%;
  padding-bottom: 0;
  @media (max-width: 1024px) {
    width: 100%;
    padding-bottom: 0;
  }

  .news-slide-image-wrapper {
    margin-top: 0;
  }
}
.prod-detail-description {
  background-color: white;
  width: 50%;
  @media (max-width: 1024px) {
    width: 100%;
    padding: 7rem 0;
    padding-bottom: 0;
    padding-top: 0;
  }
  @media (max-width: 767px) {
    padding: 5rem 0;
    padding-bottom: 0;
    padding-top: 0;
  }
  @media (max-width: 576px) {
    padding: 3rem 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  padding-right: 2rem;
  padding-left: 3rem;
 
  @media (min-width: 1025px) and (max-width: 1200px) {
    padding-left: 3rem;
  }
  // }
}
.prod-detail-description h2 {
  padding-top: 1rem;
  color: #222;
}


/** Product Intro Button**/

.prod-detail-btn {
  margin-top: 4rem;
  border: 1px solid #f77b00;
  border-radius: 45px;
  width: 40%;
  @media (min-width: 1025px) and (max-width: 1380px) {
    width: 50%;
  }
  @media (min-width: 770px) and (max-width: 1024px) {
    width: 40%;
  }
  @media (min-width: 601px) and (max-width: 769px) {
    width: 55%;
  }
  @media (max-width: 600px) {
    width: 50%;
  }
  @media (max-width: 480px) {
    width: 70%;
  }
  @media (max-width: 350px) {
    width: 80%;
  }
  padding: 8px 7px;
  display: inline-block;
  text-align: center;
}
.prod-detail-btn:hover {
  border: 1px solid #009cad;
  background-color: #016e7a;
  color: white;
  transition: all 0.7s ease-in-out;
}
.prod-detail-btn a {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  gap: 1rem;
  text-transform: uppercase;
  color: #f77b00;
}

/*** Product Detail Navigation***/

.prod-detail-nav-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  // margin-bottom: 5px;
  a {
    font-size: 14px;
    white-space: nowrap;
    @media (max-width: 576px) {
      font-size: 12px;
    }
  }
}

.prod-detail-nav {
  font-size: 16px;
  font-weight: 300;
  color: #858585;
  cursor: pointer;
}
.prod-detail-nav2{
  cursor: default;
}
.prod-detail-nav:hover {
  color: #f77b00;
}

/******    Prefab Intro with Icons*******/

.prod-detail-container-prefab {
  margin-bottom: 80px;
}

/******************* Prefab Intro Icons Grid********************/

.prefab-intro-icons {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  @media (max-width: 1024px) {
    justify-content: space-evenly;
  }
    @media (max-width: 767px) {
      gap: 30px;
    }
    @media (max-width: 576px) {
      gap: 10px;
    }
    @media (max-width: 350px) {
      gap: 0;
    }
  padding-bottom: 30px;
  // justify-content: space-between;
  width: 100%;
}
.prefab-icon-wrap {
  display: flex;
  align-items: center;
  gap: 10px;
  @media (max-width: 576px) {
    flex-direction: column;
    justify-content: center;
  }
  // align-self: flex-start;
}
.prefab-icon-wrap img {
  // width: 100%;
  width: 40px;
  height: 40px;
  @media (max-width: 767px) {
    width: 30px;
    height: 30px;
  }
  @media (max-width: 576px) {
    width: 25px;
    height: 25px;
  }
  // @media (max-width: 550px) {
  //   height: 100%;
  // }

  object-fit: cover;
  object-position: center;
}
.prefab-icon-img4 {
  width: 50px !important;
  height: 30px !important;
  @media (max-width: 767px) {
    width: 40px !important;
  height: 25px !important;
  }
  @media (max-width: 576px) {
    width: 31px !important;
  height: 19px !important;
  }
}
.prefap-spec {
  font-weight: 500;
  color: #222;
  font-size: 2rem;
  @media (max-width: 1024px) {
    font-size: 1.6rem;
  }
  @media (max-width: 767px) {
    font-size: 15px;
  }
  @media (max-width: 576px) {
    font-size: 14px;
  }
}

.prefab-icon-title {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  // align-self: flex-end;
  height: 100%;
  width: 100%;
}
.prefab-spec-title {
  font-size: 12px;
  font-weight: 300;
  color: #6a6f75;
  padding-top: 10px;
 
  @media (max-width: 576px) {
    padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  }
  @media (max-width: 400px) {
    font-size: 10px;
  }
}

.icon-bed img {
  @media (max-width: 1024px) {
    width: 42px;
    height:42px;
  }
  @media (max-width: 767px) {
    width: 32px;
    height:32px;
  }
  @media (max-width: 576px) {
    width: 25px;
    height:25px;
  }
}
.prod-detail-desc{
  // font-size: 1.6rem;
  color: #868585;
  // margin-top: 3rem;
  text-align: justify;
  @media (max-width: 1400px) {
    font-size: 14px;
    line-height: 20px;
    // margin-top: 2rem;
  }
  @media (max-width: 767px) {
    // margin-top: 1.5rem;
  }
  @media (max-width: 400px) {
    text-align: left;
  }

}