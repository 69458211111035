.home-news-wrapper {

    background-color: rgba(175, 172, 172, 0.1);
  }
  
  .home-news {
    max-width: 1280px;
    width: 100%;
    flex-direction: column;
    align-items: center;
  
    .top {
      width: 100%;
      max-width: 540px;
      > * {
        text-align: center;
      }
  
      .title1 {
          color: #036eb6;
          margin-bottom: 20px;
          line-height: 24px;
          font-weight: 600;
          text-transform: uppercase;
          @media (max-width: 1024px) {
              font-size: 15px;
              line-height: 22px;
          }
          @media (max-width: 767px) {
              font-size: 14px;
              line-height: 21px;
          }
      }
  
      .title2 {
        margin-bottom: 20px;
        color: rgb(34, 35, 52);
        font-size: 39px;
        font-weight: 700;
        letter-spacing: -1.5;
        line-height: 47px;
        font-family: Raleway, sans-serif;
  
        @media (max-width: 1024px) {
          font-size: 31px;
          line-height: 37px;
        }
  
        @media (max-width: 767px) {
          font-size: 25px;
          line-height: 30px;
        }
        @media (max-width: 576px) {
          font-size: 18px;
          line-height: 22px;
      }
      }
  
      .text {
        color: rgb(97, 99, 121);
        margin-bottom: 34px;
        font-size: 16px;
        line-height: 24px;
  
        @media (max-width: 1024px) {
          font-size: 15px;
          line-height: 22px;
        }
  
        @media (max-width: 767px) {
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  
    .row {
      display: flex;
      justify-content: center;
      width: 100%;
      @media (max-width: 767px) {
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
        justify-content: flex-start;
      }
    }
  
    .news-card {
      max-width: 373px;
      width: 100%;
      // height: 532px;
      margin: 10px;
      > * {
        background-color: rgba(242, 242, 242, 0.1);
  
      }
      
      @media (max-width: 1300px) {
        height: fit-content;
      }
  
      @media (max-width: 767px) {
        max-width: 500px;
        width: 100%;
        margin: 10px 0;
      }
  
      .img-wrapper {
        width: 100%;
        max-height: 180px;
        overflow: hidden;
        > img {
          max-height: 250px;
  
          @media (max-width: 1024px) {
            max-height: 150px;
          }
  
          height: fit-content;
          width: 100%;
          object-fit: cover;
        }
      }
      .content {
        padding-top: 30px;
        @media (max-width: 767px) {
          padding-top: 20px;
        }
      }
      .time {
        color: rgb(175, 172, 172);
        font-family: Inter, sans-serif;
        font-size: 14px;
        margin-bottom: 10px;
        height: 17px;
      }
  
      .title {
        color: rgb(34, 35, 52);
        font-family: Raleway, sans-serif;
        font-weight: 700;
        letter-spacing: -0.5;
        line-height: 28px;
        font-size: 20px;
        margin-bottom: 10px;
        text-transform: capitalize;
        @media (max-width: 1024px) {
          font-size: 18px;
          line-height: 25px;
        }
  
        @media (max-width: 767px) {
          font-size: 16px;
          line-height: 22px;
        }
      }
  
      .text {
        color: rgb(97, 99, 121);
        font-family: Inter, sans-serif;
        font-weight: 400;
        line-height: 21px;
        font-size: 14px;
        padding-bottom: 14px;
        //margin-bottom: 14px;
        @media (max-width: 1024px) {
          font-size: 13px;
          line-height: 19px;
        }
  
        @media (max-width: 767px) {
          font-size: 12px;
          line-height: 18px;
        }
      }
  
      .nav-btn {
        width: 137px;
        font-size: 12px;
        padding: 12px 24px;
        margin-bottom: 21px !important;
      }
    }
    .nav-btn-news {
      background-color: none;
      color: var(--color-primary);
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 7px;
    }
    .nav-btn-news:hover{
      color: var(--color-secondary);
      transition: all 0.3s ease-out;
    }
  }
  .cards-wrapper {
    width: 100%;
    .row {
        width: 100%;
        justify-content: space-between;
    }
}