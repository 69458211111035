.specification-table{
  padding-top: 0;
  flex-direction: column;
  row-gap: 20px;
  p {
    font-size: 15px;
    @media (max-width: 767px) {
      font-size: 14;
    }
    @media (max-width: 576px) {
      font-size: 12px;
    }
  }
}

.product-spec{
  width: 100%;
  margin: auto;
  @media (max-width: 900px) {
    padding-top: 0;
  }
}
.product-spec h3{
 padding-bottom: 10px;
 color: #222;
}

.table-spec-1 {
   width: 100%;
  border-collapse: collapse;
}
.table-spec-1 td,
.table-spec-1 th {
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: center;
  
}

.table-spec-1 td {
  white-space: nowrap;
  @media (max-width: 576px) {
    white-space:normal;
  }
}

.table-spec-1 th{
  background-color: #2371ba;
}

.table-spec-1 th p{
  color: #fff;
  font-weight: 500;
  font-size: 15px;
    @media (max-width: 767px) {
      font-size: 14;
    }
    @media (max-width: 576px) {
      font-size: 12px;
      font-weight: 400;
    }
  }


.table-spec-1 tbody tr:nth-child(even) {
  background-color: #eaeaea;
}

@media screen and (max-width: 768px) {
  .table-spec-1 thead {
    display: none;
  }
  .table-spec-1 tbody,
  .table-spec-1 tr,
  .table-spec-1 td {
    display: block;
    width: 100%;
  }
  .table-spec-1 tr {
    margin-bottom: 15px;
  }
  .table-spec-1 td {
    text-align: right;
    padding-left: 50%;
    text-align: right;
    position: relative;
  }
  .table-spec-1 td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: fit-content;
    padding-left: 15px;
    text-align: left;
    font-weight: 500;
    font-weight: 500;
  font-size: 15px;
    @media (max-width: 767px) {
      font-size: 13;
    }
    @media (max-width: 576px) {
      font-size: 12px;
      width: 50%;
      // font-weight: 400;
    }
  }
}


