.banner-wrapper {
  position: relative;
  width: 100%;
}

.banner-common {
  width: 100%;
  height: 400px;

  @media (max-width: 1024px) {
    height: 330px;
  }

  @media (max-width: 768px) {
    height: 230px;
  }

  @media (max-width: 566px) {
    height: 250px;
  }

}


.banner-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(90deg,
      rgba(0, 0, 0, 0.7539390756302521) 0%,
      rgba(0, 0, 0, 0.7035189075630253) 16%,
      rgba(0, 0, 0, 0.5018382352941176) 35%,
      rgba(0, 0, 0, 0) 100%);
}

.banner-title {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.9);
  padding: 0 80px;

  @media (max-width: 1200px) {
    padding: 0 60px;
  }

  @media (max-width: 992px) {
    padding: 0 40px;
  }

  @media (max-width: 767px) {
    padding: 0 30px;
  }

  @media (max-width: 576px) {
    padding: 0 20px;
  }
}

.banner-title-span {
  text-transform: capitalize;
  margin-bottom: 10px;
}

.banner-text-span {
  font-weight: 400;
  font-size: 20px;
  width: 50%;

  @media (max-width: 1024px) {
    font-size: 18px;
    width: 60%;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    width: 70%;
  }

  @media (max-width: 550px) {
    font-size: 14px;
    width: 90%;
  }
}

.banner-header {
  width: 100%;
  margin: auto;
  margin-top: 70px;
  flex-direction: column;
  align-items: flex-start;

}