.news-comp {
  max-width: 1280px;
  width: 100%;
  padding: 112px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1024px) {
      padding: 45px 0;
  }

  @media (max-width: 767px) {
      padding: 28px 0;
  }

  .row {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 80px;
      @media (max-width: 1024px) {
          margin-bottom: 60px;
      }
      @media (max-width: 767px) {
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 40px;
          row-gap: 25px;
      }
      .news-card {
          max-width: 353px;
          width: 100%;
          // height: 532px;
          margin: 10px;
          background-color: #fff;
  
          @media (max-width: 1300px) {
              height: fit-content;
          }
  
          @media (max-width: 767px) {
              max-width: 500px;
              width: 100%;
             margin: 0 ;
          }
  
          .img-wrapper {
              width: 100%;
  
              >img {
                  max-height: 250px;
  
                  @media (max-width: 1024px) {
                      max-height: 150px;
                  }
  
                  height: fit-content;
                  width: 100%;
                  object-fit: cover;
              }
          }
          .content {
              padding-top: 30px;
              @media (max-width: 767px) {
                  padding-top: 20px;
              }
          }
          .time {
              color: rgb(175, 172, 172);
              font-family: Inter, sans-serif;
              font-size: 14px;
              margin-bottom: 10px;
          }
  
          .title {
              color: rgb(34, 35, 52);
              font-family: Raleway, sans-serif;
              font-weight: 700;
              letter-spacing: -0.5;
              line-height: 28px;
              font-size: 20px;
              margin-bottom: 10px;
              @media (max-width: 1024px) {
                  font-size: 18px;
                  line-height: 25px;
              }
  
              @media (max-width: 767px) {
                  font-size: 16px;
                  line-height: 22px;
              }
          }
  
          .text {
              color: rgb(97, 99, 121);
              font-family: Inter, sans-serif;
              font-weight: 400;
              line-height: 21px;
              font-size: 14px;
              margin-bottom: 14px;
              max-height: 60px;
              overflow-y: hidden;
             // margin-bottom: 14px;
              @media (max-width: 1024px) {
                  font-size: 13px;
                  line-height: 19px;
              }
  
              @media (max-width: 767px) {
                  font-size: 12px;
                  line-height: 18px;
              }
          }
  
          .nav-btn {
              width: 137px;
              font-size: 12px;
              padding: 12px 24px;
              margin-bottom: 21px !important;
          }
      }
  }
  .nav-btn-news {
      background-color: none;
      color: rgb(3, 110, 182);
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 7px;
    }
    .nav-btn-news:hover{
      color: rgb(0, 70, 117);;
      transition: all 0.3s ease-out;
    }

}