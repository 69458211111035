.slide-image-wrapper {
    max-width: 100vw;
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 737px;
    @media (max-width: 1600px) {
        height: 677px;
    }
    @media (max-width: 1200px) {
        height: 577px;
    }
    @media (max-width: 767px) {
        height: 477px;
    }
    @media (max-width: 576px) {
        height: 377px;
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 22;
        height: 100%;
        width: 100%;
        background: rgb(0,0,0);
        background: linear-gradient(90deg, rgba(0,0,0,0.5) 0%, rgba(0, 0, 0, 0.1) 100%);
    }
    .bottom-bullets {
        position: absolute;
        bottom: 20px;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        .bullet {
            width: 9px;
            height: 9px;
            background-color: rgba(150, 150, 150, 0.523);
            // border: 1px solid #fff;
            z-index: 33;
            margin: 4px;
            border-radius: 10px;
            cursor: pointer;
            @media (max-width: 767px) {
                width: 7px;
                height: 7px;
            }
        }
        .bullet-active {
            background-color: var(--color-primary);
        }
    }
    .banner-text-wrapper {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 33;
        width: 100%;
        padding-top: 70px;
        .banner-text {
            width: 100%;
            justify-content: flex-start;
        }
        .banner-content {
            width: 100%;
            .btn2 {
                margin-top: 40px;
             
                 @media (max-width: 1024px) {
                  margin-top: 30px;
                }
                @media (max-width: 767px) {
                margin-top: 20px;
                font-size: 11px;
                padding: 7px 20px;
                line-height: 22px;
              }
              @media (max-width: 480px) {
                margin-top: 15px;
                font-size: 10px;
                font-weight: 400;
                padding: 5px 15px;
                line-height: 18px;
              }
              }
            
              
            //   @media (max-width: ) {
                
            //   }
            h1, p {
                color: #fff;
                // max-width: 700px;
                margin: 0;
                width: 100%;
                @media (max-width: 1024px) {
                    // max-width: 600px;
                }

                // padding-bottom: 40px;
                text-transform: capitalize;
                @media (max-width: 767px) {
                    // padding-bottom: 30px;
                    // max-width: 500px;
                }
                
            }
            p {
                padding-top: 35px;
                padding-bottom: 40px;
                @media (max-width: 767px) {
                    padding-top: 20px;
                }
            }
          
        }
    }
    .left-right-btn {
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        display: flex;
        justify-content: space-between; 
        z-index: 34;
        > span {
            opacity: 0;
        }    
    }
}
.slide-image-wrapper:hover {
    .left-right-btn {
         > span {
            width: 40px;
            height: 50px;
            // @media (max-width: 1200px) {
            //     width: 20px;
            //     height: 40px;
            // }
            > * {
                color: var(--color-primary);
                
            }
            // background-color: var(--color-primary);
            transition: all 0.2s ease-in;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
            opacity: 1;
            > * {
                
                // width: 20px;
                // height: 20px;
                font-weight: 500;
            }
        }
        > span:hover {
            background-color: var(--color-primary);
            transition: all 0.2s ease-in;
            border: 0;
            > * {
                color: #fff;
            }
        }
        @media (max-width: 992px) {
            display: none;
        }
    }
}

.slide-image {
    display: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    animation-name: zoom;
    animation-duration: 10s;
}

.slide-image-active {
    display: block;
}

@keyframes zoom {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.1);
    }
  }