.virtual-detail-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .virtual-detail-wrapper {
    width: 65%;
    @media screen and (max-width: 768px) {
      width: 95%;
    }
    @media screen and (min-width:769px) and (max-width: 1500px) {
      width: 80%;
    }
  
    background-color: #f8f8f8;
    margin: 2% 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5rem 0;
    border-radius: 30px;
  }
  .virtual-detail-close {
    display: flex;
    width: 90%;
    justify-content: flex-end;
    align-items: center;
    margin-top: 3rem;
  }
  .virtual-detail-img1 {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .virtual-detail-img1 img {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  .virtual-detail-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .virtual-detail-content h2 {
    width: 80%;
    padding: 3rem 0;
  }
  .virtual-detail-content p {
    width: 80%;
    // padding: 3rem 0;
  }
  