.our-team-wrapper {
    
    .our-team {
        flex-direction: column;
        align-items: center;
        .top {
            display: flex;
            flex-direction: column;
            align-items: center;
            p {
                text-align: center;
                max-width: 900px;
                @media (max-width: 576px) {
                    // text-align: justify;
                }
            }
            margin-bottom: 50px;
            @media (max-width: 1024px) {
                margin-bottom: 40px;
            }
            // @media (max-width: 767px) {
            //     margin-bottom: 40px;
            // }
            // @media (max-width: 576px) {
            //     margin-bottom: 40px;
            // }
        }
        .imgs-wrapper {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            grid-template-rows: repeat(2, 1fr);
            gap: 30px;
            @media (max-width: 576px) {
                grid-template-columns: repeat(1, 100%);
                grid-template-rows: repeat(4, 1fr);
                gap: 0;
                row-gap: 20px;
            }
        }
        .img:nth-child(1) {
            grid-column: 1 / 5;
        }
        .img:nth-child(2) {
            grid-column: 5 / 7;
            @media (max-width: 576px) {
                grid-column: -2 / 5;
            }
        }
        .img:nth-child(3) {
            grid-column: -7/ -4;
            @media (max-width: 576px) {
                grid-column: -3 / 5;
            }
        }
        .img:nth-child(4) {
            grid-column: -4 / -1;
            @media (max-width: 576px) {
                grid-column: -4 / 5;
            }
        }
        .img {
            max-height: 500px;
            overflow: hidden;
            > img {
                width: 100%;
                height: 100%;
                max-height: 350px;
                object-fit: cover;
                object-position: center;
                border-radius: 5px;
                @media (max-width: 576px) {
                    max-height: 250px;
                }
            }
        }
       
    }
}