//tooltips
section {
  height: 100vh;
  width: 100%;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  
}

.virtual-tour-topbar{
  width: 100%;
  height: 100%;
}
.tooltipcontainer {
  background-image: url(./AboutBannerBg.jpg);
  display: inline-block;
  // background-size: contain;
  // background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-width: 1920px;
  // max-width: 100vw;
  height: 100vh;
  // margin: 0 auto;
  position: relative;
  overflow-x: auto;
  // overscroll-behavior-inline: contain;
  overflow-y: hidden;
  white-space: nowrap;
}
.tooltipcontainer img {
  height: 100%;
  width: 100%;
}
.all-tooltip {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.tooltip-content {
  position: absolute;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 20px #00000020;
  padding: 30px;
  border-radius: 8px;
  width: 80vw;
  max-width: 300px;
  opacity: 0;
  pointer-events: none;
  /* left: -50%; */
  z-index: 2;
}
.tooltip-content .arrow {
  position: absolute;
  width: 10px;
  height: 10px;
  border: 10px solid transparent;
  border-bottom-color: rgb(255, 255, 255);
  top: 0px;
  left: 50%;
  transform: translate(-50%, -100%) rotate(0deg);
}

.pin {
  position: absolute;
  content: "";
  top: 50%;
  left: 80%;
  height: 25px;
  width: 25px;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  cursor: pointer;
}
.pin:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  animation: puls-effect 1s ease infinite;
}
.tooltip.active .tooltip-content {
  opacity: 1;
  pointer-events: all;
  display: flex;
  flex-direction: column;
}

/* Custom Style for tooltip-1 */

.tooltip-1 .pin {
  top: 23%;
  left: 21%;
  height: 15px;
  width: 15px;
  background-color: rgb(255, 255, 255);
}
.tooltip-1 .tooltip-content {
  display: flex;
  max-width: 300px;
  align-items: stretch;
  justify-content: center;
  padding: 0;
  background-color: aliceblue;
  z-index: 5;
}

.tooltip-1.active {
  display: flex;
}
.tooltip-1 .tooltip-content .img {
  width: 100%;
  padding: 1rem;
  object-fit: cover;
}
.tooltip-1 .tooltip-content .content {
  width: 100%;
  padding: 10px 10px;
}
.tooltip-1 .tooltip-content .content h3 {
  font-size: 20px;
  width: 100%;
  white-space: pre-wrap;
}
.tooltip-1 .tooltip-content .content p {
  font-size: 14px;
}
// .tooltip-1 .tooltip-content .content button {
//   font-size: 18px;
//   font-weight: 400;
//   border: none;
//   outline: none;
//   background-color: white;
//   color: black;
//   padding: 10px;
//   cursor: pointer;
//   margin-top: 20px;
//   border-radius: 4px;
//   box-shadow: 0px 0px 20px #00000020;
// }

/* custom style for tooltip-2 */
.virtual-detail-btn {
  padding: 10px 10px;
  text-align: center;
}
.tooltip-2 .pin {
  top: 27%;
  left: 35%;
  height: 15px;
  width: 15px;
}
.tooltip-2 .tooltip-content .content h3 {
  font-size: 20px;
  width: 100%;
  white-space: pre-wrap;
}
.tooltip-2 .tooltip-content {
  display: flex;
  max-width: 300px;
  align-items: stretch;
  justify-content: center;
  padding: 0;
  background-color: aliceblue;
  z-index: 5;
}
.tooltip-2 .tooltip-content .img {
  width: 100%;
  padding: 1rem;
  object-fit: cover;
}
.tooltip-2 .tooltip-content .content {
  width: 100%;
  padding: 20px;
}
// .tooltip-2 .tooltip-content button {
//   font-family: "Montserrat";
//   font-size: 18px;
//   font-weight: 400;
//   border: none;
//   outline: none;
//   background-color: white;
//   color: black;
//   padding: 10px;
//   cursor: pointer;
//   margin-top: 20px;
//   border-radius: 4px;
//   box-shadow: 0px 0px 20px #00000020;
// }

/* Custom style for tooltip-3 */

.tooltip-3 .pin {
  top: 29%;
  left: 45%;
  height: 15px;
  width: 15px;
}
.tooltip-3 .tooltip-content .content h3 {
  font-size: 20px;
  width: 100%;
  white-space: pre-wrap;
}
.tooltip-3 .tooltip-content {
  display: flex;
  max-width: 300px;
  align-items: stretch;
  justify-content: center;
  padding: 0;
  background-color: aliceblue;
  z-index: 5;
}
.tooltip-3 .tooltip-content .img {
  width: 100%;
  padding: 1rem;
  object-fit: cover;
}
.tooltip-3 .tooltip-content .content {
  width: 100%;
  padding: 20px;
}

/* Custom style for tooltip-4 */

.tooltip-4 .pin {
  top: 37%;
  left: 65%;
  height: 15px;
  width: 15px;
}
.tooltip-4 .tooltip-content .content h3 {
  font-size: 20px;
  width: 100%;
  white-space: pre-wrap;
}
.tooltip-4 .tooltip-content {
  display: flex;
  max-width: 300px;
  align-items: stretch;
  justify-content: center;
  padding: 0;
  background-color: aliceblue;
  z-index: 5;
}
.tooltip-4 .tooltip-content .img {
  width: 100%;
  padding: 1rem;
  object-fit: cover;
}
.tooltip-4 .tooltip-content .content {
  width: 100%;
  padding: 20px;
}

/* Puls Effect For Pins */

@keyframes puls-effect {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
}

/* Media Query */

@media (max-width: 768px) {
  .tooltip-1 .tooltip-content {
    flex-direction: column;
    max-width: 250px;
  }
  .tooltip-1 .tooltip-content .content,
  .tooltip-1 .tooltip-content .img {
    width: 100%;
  }
}

.tooltip-1 > .active {
  opacity: 1;
}

.virtual-tour-return {
  position: absolute;
  z-index: 100;
  background-color: #f77b00;
  width: 45px;
  height: 45px;
  // @media screen and (max-width:700px) {
  //   width: 60px;
  //   height: 60px; 
  // }
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  top: 90%;
  margin-left: 3rem;
  margin-bottom: 1rem;
  
}
