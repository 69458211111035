.home-about-wrapper {
    .home-about {
        padding-top: 111px;
        padding-bottom: 126px;
        justify-content: space-between;
        @media (max-width: 992px) {
            flex-direction: column;
            align-items: center;
            padding-top: 80px;
            padding-bottom: 80px;
        }
        @media (max-width: 767px) {
            padding-top: 50px;
            padding-bottom: 50px;
        }
        @media (max-width: 576px) {
            padding-top: 30px;
            padding-bottom: 30px;
        }
        >div {
            width: 50%;
            @media (max-width: 992px) {
                width: 100%;
                max-width: 720px;
            }
        }
        .left {
            .btn2 {
                margin-top: 30px;
                // background-color: #000;
            }
            // .btn:hover {
            //     box-shadow: rgba(204, 204, 204, 0.6) 0px 0px 10px 3px;
            // }
           
            .hr {
                margin-top: 20px;
            }
            p {
                max-width: 550px;
                @media (max-width: 992px) {
                    max-width: none;
                }
            }
            // .bp-text {
            //     margin-top: 20px;
            // }
            

            .list-wrapper {
                display: flex;
                justify-content: space-between;
                column-gap: 20px;
                width: 100%;
                padding-top: 20px;
                > div {
                    width: 50%;
                }
                @media (max-width: 350px) {
                    flex-direction: column;
                    > div {
                        width: 100%;
                    }
                }
                p {
                    display: flex;
                    align-items: center;
                }
                .about-icon {
                    display: flex;
                    height: 100%;
                    align-items: center;
                > * {
                    color: var(--color-primary);
                    padding-right: 10px;
                    width: 30px;
                    height: 30px;
                }
            }
            }
        }

        .right {
            display: flex;
            justify-content: flex-end;
            width: calc(50% - 100px);
            @media (max-width: 992px) {
                width: 100%;
                 margin-top: 45px;
            }
            .img-wrapper{
                position: relative;
                // margin-bottom: 40px;
                // padding-right: 23px;
                // padding-top: 20px;
                overflow: hidden; 
                width: 100%;
                height: 100%;
            }
            // .img-wrapper::before {
            //     position: absolute;
            //     content: '';
            //     right: 0px;
            //     top: 0px;
            //     left: 30px;
            //     bottom: 30px;
            //     background-color: var(--color-primary);
            //     z-index: 11;
            // }
            .img {
                // position: relative;
                // max-width: 600px;
                // max-height: 350px;
                // // padding-left: 50px;
                // object-fit: cover;
                width: 100%;
                z-index: 22;
                // box-shadow: 10px 0px 20px rgba(39, 38, 38, 0.3);
                @media (max-width: 992px) {
                   
                    max-width: 720px;
                    max-height: 480px;
                    padding-left: 0;
                }
                // @media (max-width: 767px) {
                //     margin-top: 50px;
                // }
                
            }
            
        }
    }
}