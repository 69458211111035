.numbers-div {
  background-color: azure;
  background-image: url("../../img/PoleProduction.jpg");
  background-position: center;
  background-size: cover;
  position: relative;
  .num-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    //background: rgba(0, 0, 0, 0.9);
    //background-color: rgba(30, 39, 46, 0.95);
    background-color: rgba(13, 18, 22, 0.9);
  }
}
.num{
  display: flex;
  white-space: nowrap;
  align-items: start baseline;
  justify-content: center;
  h3{
    color: white;
    margin-bottom: 0;
  }
}
.num h2{
  position: relative;
  display: flex;
}
.num h2::after{
  content: "";
  left: 50%;
  height: 10px;
  position: absolute;
  top: -25px;
  width: 10px;
  background-color: var(--color-secondary);
  border-radius: 50%;
}

.top-title-color {
  color: var(--color-secondary);
}
.numbers-wrapper {
  flex-direction: column;
}
.numbers-container {
  width: 100%;
  padding-top: 55px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  @media(max-width:768px){
    grid-template-columns: repeat(2, 2fr);
    row-gap: 50px;
  }
  @media(min-width: 489px) and (max-width:768px){
    width: 80%;
    margin: auto;
  }
  place-items: center;
  .num-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .icon {
    
      border: 1px solid rgba(255, 255, 255, 0.3);
      border-radius: 5px;
      padding: 18px;
      img {
        width: 38px;
        height: 38px;
      }
      .num-dots {
        width: 5px;
        height: 5px;
        background-color: var(--color-secondary);
        border-radius: 50%;
      }
      margin-bottom: 45px;
    }
  }
  z-index: 5;
}
.numbers-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > * {
    color: white;
  }
  z-index: 5;
}
.num-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    color: rgba(255, 255, 255, 0.85);
    font-weight: 600;
    font-family: "Inter", sans-serif;
    white-space: nowrap;
  }
}
.description{
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
}
.bottom-text {
  color: var(--color-accent3);
  z-index: 5;
  text-align: center;
  padding-top: 40px;
}
.bottom-text-color {
  color: var(--color-secondary);
}
